export enum TextCustomizationSettingType {
  fontSize = "fontSize",
  fontWeight = "fontWeight",
  fontName = "fontName",
  fontColor = "fontColor",
  shadowBlur = "shadowBlur",
  shadowColor = "shadowColor",
  text = "text"
}

export interface TextCustomizationSetting {

}
